import { StyleSheet } from "react-native";

export const AppStyles = StyleSheet.create({
  card: {
    backgroundColor: "#FFFFFF",
    margin: 8,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.5,
    shadowRadius: 5,
    elevation: 5,
    borderRadius: 5,
  },
});
