import AsyncStorage from "@react-native-async-storage/async-storage";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import Constants from "expo-constants";
import { logEvent, setClientId } from "expo-firebase-analytics";
import * as StoreReview from "expo-store-review";
import { useEffect } from "react";
import "react-native-gesture-handler";
import uuid from "react-native-uuid";
import EditSequenceList from "./src/screen/EditSequenceList";
import EditTimer from "./src/screen/EditTimer";
import MainList from "./src/screen/MainList";
import RunTimer from "./src/screen/RunTimer";
import SelectTimerType from "./src/screen/SelectTimerType";

const Stack = createStackNavigator();

export default function App() {
  useEffect(() => {
    if (Constants.appOwnership == "expo") setClientId(uuid.v4());
    logEvent("launch");

    (async () => {
      try {
        const version = Constants.manifest.version.split(".")[0];
        const launchesKey = `${version} launches`;

        let reviewRequested = await AsyncStorage.getItem("reviewRequested");
        if (reviewRequested == version) return;

        let launches = parseInt(await AsyncStorage.getItem(launchesKey));
        if (isNaN(launches)) launches = 0;
        launches++;
        await AsyncStorage.setItem(launchesKey, launches.toString());

        if (launches >= 10 && (await StoreReview.hasAction())) {
          await StoreReview.requestReview();
          await AsyncStorage.setItem("reviewRequested", version);
          logEvent("requestReview");
        }
      } catch (e) {
        // NOOP
      }
    })();
  }, []);

  return (
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen
          name='MainList'
          component={MainList}
          options={{ title: "My Timers" }}
        />
        <Stack.Screen
          name='EditTimer'
          component={EditTimer}
          options={{ title: "Edit Timer" }}
        />
        <Stack.Screen
          name='SelectTimerType'
          component={SelectTimerType}
          options={{ title: "Select Timer Type" }}
        />
        <Stack.Screen
          name='RunTimer'
          component={RunTimer}
          options={({ route }) => ({ title: route.params.title })}
        />
        <Stack.Screen
          name='EditSequenceList'
          component={EditSequenceList}
          options={({ route }) => ({ title: route.params.title })}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
