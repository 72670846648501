import AsyncStorage from "@react-native-async-storage/async-storage";
import { logEvent } from "expo-firebase-analytics";
import { useEffect, useState } from "react";
import { View } from "react-native";
import TimerList from "../component/TimerList";
import { EXAMPLES } from "../timer/Examples";

export default function MainList() {
  const [data, setData_] = useState([]);

  let setData = async d => {
    setData_(d);
    await AsyncStorage.setItem("timers", JSON.stringify(d));
    logEvent("timers_persisted", { count: d.length });
  };

  useEffect(() => {
    AsyncStorage.getItem("timers").then(timersJsonStr => {
      setData(timersJsonStr ? JSON.parse(timersJsonStr) : EXAMPLES());
    });
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <TimerList data={data} setData={setData} />
    </View>
  );
}
