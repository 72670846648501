import { useNavigation } from "@react-navigation/native";
import { useMemo } from "react";
import { Platform, TouchableOpacity, View } from "react-native";
import { NewTimer, TimerTypeMap } from "../timer/Timers";
import TextPropertyView from "./TextPropertyView";
import TimerRow from "./TimerRow";

export default function EditRepeat({ data, onUpdate }) {
  const navigation = useNavigation();
  let fields = [
    { type: "String", name: "name" },
    ...TimerTypeMap[data.type].fields.filter(t => t.name != "delegate"),
  ];
  const delegate = useMemo(() => {
    return data.delegate || NewTimer();
  }, [data.delegate]);

  let rowClick = d => {
    return () => {
      navigation.push("EditTimer", {
        data: JSON.parse(JSON.stringify(d)),
        onSave: row => {
          onUpdate({
            ...data,
            delegate: row,
          });
          navigation.goBack();
        },
      });
    };
  };
  return (
    <View>
      {fields.map(f => (
        <TextPropertyView
          key={f.name}
          data={data}
          field={f}
          onUpdate={onUpdate}
        />
      ))}
      <TouchableOpacity
        onPress={rowClick(delegate)}
        style={{
          ...Platform.select({
            web: {
              cursor: "pointer",
            },
          }),
        }}>
        <TimerRow data={delegate} />
      </TouchableOpacity>
    </View>
  );
}
