import { useNavigation } from "@react-navigation/native";
import { useLayoutEffect } from "react";
import {
  Button,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import DraggableFlatList from "react-native-draggable-flatlist";
import { AppStyles } from "../../Styles";
import TimerRow from "../component/TimerRow";
import { NewTimer } from "../timer/Timers";

export default function TimerList({ data, setData }) {
  const navigation = useNavigation();

  let onSave = row => {
    let i = data.findIndex(d => d.id == row.id);
    if (i != -1) {
      setData([...data.slice(0, i), row, ...data.slice(i + 1, data.length)]);
    } else {
      setData(data.concat(row));
    }
    navigation.goBack();
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <View
          style={{
            marginRight: 8,
          }}>
          <Button
            onPress={() => {
              navigation.push("EditTimer", {
                data: NewTimer(),
                onSave,
              });
            }}
            title='Create'
          />
        </View>
      ),
    });
  });

  let rowClick = d => () =>
    navigation.push("EditTimer", {
      data: JSON.parse(JSON.stringify(d)),
      onSave,
      onDelete: row => {
        let i = data.findIndex(d2 => d2.id == row.id);
        if (i == -1) return;
        setData([...data.slice(0, i), ...data.slice(i + 1, data.length)]);
        navigation.goBack();
      },
    });

  return (
    <DraggableFlatList
      data={data}
      style={{ height: "100%" }}
      renderItem={({ item, drag }) => (
        <TouchableOpacity
          onLongPress={drag}
          style={StyleSheet.compose(styles.rowContainer, AppStyles.card)}
          onPress={rowClick(item)}>
          <TimerRow data={item} />
        </TouchableOpacity>
      )}
      keyExtractor={item => `draggable-item-${item.id}`}
      onDragEnd={({ data }) => setData(data)}
    />
  );
}

const styles = StyleSheet.create({
  rowContainer: {
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },
});
