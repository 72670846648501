import * as Clipboard from "expo-clipboard";
import { logEvent } from "expo-firebase-analytics";
import React, { useMemo, useState } from "react";
import { Alert, Button, Platform, Text, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { AppStyles } from "../../Styles";
import EditCountdown from "../component/EditCountdown";
import EditRepeat from "../component/EditRepeat";
import EditSequence from "../component/EditSequence";
import EditTabata from "../component/EditTabata";
import TimerTypeRow from "../component/TimerTypeRow";
import { Timer } from "../timer/Timers";

export default function EditTimer({ route, navigation }) {
  let { data, onSave, onDelete } = route.params;

  let [workingData, setWorkingData] = useState(() =>
    JSON.parse(JSON.stringify(data)),
  );
  let totalTime = useMemo(
    () => (workingData ? Timer(workingData).getFormattedTime() : ""),
    [workingData],
  );

  React.useEffect(() => {
    let saved = false;
    navigation.setOptions({
      headerRight: () => (
        <View style={{ marginRight: 8, flexDirection: "row" }}>
          {onDelete ? (
            <Button
              onPress={() => {
                let title = "Are you sure you want to delete this timer?";
                if (Platform.OS == "web") {
                  if (confirm(title)) {
                    logEvent("timer_delete", {
                      timer_type: workingData.type,
                    });
                    onDelete(workingData);
                  }
                  return;
                }
                Alert.alert(title, "", [
                  {
                    text: "Cancel",
                    style: "cancel",
                    onPress: () => {},
                  },
                  {
                    text: "Delete",
                    style: "destructive",
                    onPress: () => {
                      logEvent("timer_delete", {
                        timer_type: workingData.type,
                      });
                      onDelete(workingData);
                    },
                  },
                ]);
              }}
              title='Delete'
            />
          ) : null}
          <View style={{ marginLeft: 8 }}>
            <Button
              onPress={() => {
                saved = true;
                logEvent("timer_save", { timer_type: workingData.type });
                onSave(workingData);
              }}
              title='Save'
            />
          </View>
        </View>
      ),
    });

    return navigation.addListener("beforeRemove", e => {
      if (saved || JSON.stringify(workingData) == JSON.stringify(data)) return;
      e.preventDefault();
      let title = "Discard changes?";
      let subtitle =
        "You have unsaved changes. Are you sure to discard them and leave the screen?";
      if (Platform.OS == "web") {
        if (window.confirm(title + "\n" + subtitle)) {
          navigation.dispatch(e.data.action);
        }
      } else {
        Alert.alert(title, subtitle, [
          { text: "Don't leave", style: "cancel", onPress: () => {} },
          {
            text: "Discard",
            style: "destructive",
            onPress: () => navigation.dispatch(e.data.action),
          },
        ]);
      }
    });
  }, [data, navigation, onDelete, onSave, workingData]);

  return !workingData ? null : (
    <KeyboardAwareScrollView>
      <View style={AppStyles.card}>
        <TimerTypeRow
          type={workingData.type}
          onPress={() => {
            navigation.navigate("SelectTimerType", {
              onSelect: type => {
                setWorkingData({ ...workingData, type });
              },
            });
          }}
        />
      </View>
      <View style={AppStyles.card}>
        {workingData.type == "Tabata" ? (
          <EditTabata data={workingData} onUpdate={setWorkingData} />
        ) : workingData.type == "Countdown" ? (
          <EditCountdown data={workingData} onUpdate={setWorkingData} />
        ) : workingData.type == "Sequence" ? (
          <EditSequence data={workingData} onUpdate={setWorkingData} />
        ) : workingData.type == "Repeat" ? (
          <EditRepeat data={workingData} onUpdate={setWorkingData} />
        ) : (
          <Text>Error</Text>
        )}
        <View style={{ margin: 16 }}>
          <Button
            title={`Start (${totalTime})`}
            onPress={async () => {
              logEvent("start_timer", { timer_type: workingData.type });
              navigation.navigate("RunTimer", {
                title: workingData.name,
                segments: Timer({
                  type: "Sequence",
                  delegates: [
                    {
                      type: "Countdown",
                      name: "Get Ready",
                      length: 5,
                    },
                    workingData,
                  ],
                }).toSegments(),
              });
            }}
          />
        </View>

        <View
          style={{
            flex: 1,
            margin: 16,
            flexDirection: "row",
            justifyContent: "space-around",
          }}>
          <Button
            title={`Copy`}
            onPress={async () => {
              logEvent("timer_copy", { timer_type: workingData.type });
              let json = JSON.stringify(workingData);
              Clipboard.setString(json);
            }}
          />
          <Button
            title={`Paste`}
            onPress={async () => {
              let title = `Are you sure you want to replace the current timer?`;
              let json;
              try {
                json = JSON.parse(await Clipboard.getStringAsync());
              } catch (e) {
                alert("Error reading clipboard.");
                return;
              }
              try {
                Timer(json).getFormattedTime();
                json.id = workingData.id;
                if (Platform.OS == "web") {
                  if (confirm(title)) {
                    logEvent("timer_paste", { timer_type: json.type });
                    setWorkingData(json);
                  }
                  return;
                }
                Alert.alert(title, "", [
                  {
                    text: "Cancel",
                    style: "cancel",
                    onPress: () => {},
                  },
                  {
                    text: "Replace",
                    style: "destructive",
                    onPress: () => {
                      logEvent("timer_paste", { timer_type: json.type });
                      setWorkingData(json);
                    },
                  },
                ]);
              } catch (e) {
                alert(
                  "Error pasting timer. Please ensure you are pasting the value that was copied from this app.",
                );
              }
            }}
          />
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
}
