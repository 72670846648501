import uuid from "react-native-uuid";

export const EXAMPLES = () => [
  {
    type: "Tabata",
    name: "HIIT Workout",
    rounds: 8,
    rest: 10,
    work: 20,
    id: uuid.v4(),
  },
  {
    id: uuid.v4(),
    type: "Sequence",
    name: "Brush Teeth",
    length: 30,
    delegates: [
      {
        id: uuid.v4(),
        type: "Sequence",
        name: "Top",
        length: 30,
        delegates: [
          {
            id: uuid.v4(),
            type: "Sequence",
            name: "Front",
            length: 30,
            delegates: [
              {
                id: uuid.v4(),
                type: "Countdown",
                name: "Left",
                length: 10,
              },
              {
                id: uuid.v4(),
                type: "Countdown",
                name: "Middle",
                length: 10,
              },
              {
                id: uuid.v4(),
                type: "Countdown",
                name: "Right",
                length: 10,
              },
            ],
          },
          {
            id: uuid.v4(),
            type: "Sequence",
            name: "Back",
            length: 30,
            delegates: [
              {
                id: uuid.v4(),
                type: "Countdown",
                name: "Left",
                length: 10,
              },
              {
                id: uuid.v4(),
                type: "Countdown",
                name: "Middle",
                length: 10,
              },
              {
                id: uuid.v4(),
                type: "Countdown",
                name: "Right",
                length: 10,
              },
            ],
          },
        ],
      },
      {
        id: uuid.v4(),
        type: "Sequence",
        name: "Bottom",
        length: 30,
        delegates: [
          {
            id: uuid.v4(),
            type: "Sequence",
            name: "Front",
            length: 30,
            delegates: [
              {
                id: uuid.v4(),
                type: "Countdown",
                name: "Left",
                length: 10,
              },
              {
                id: uuid.v4(),
                type: "Countdown",
                name: "Middle",
                length: 10,
              },
              {
                id: uuid.v4(),
                type: "Countdown",
                name: "Right",
                length: 10,
              },
            ],
          },
          {
            id: uuid.v4(),
            type: "Sequence",
            name: "Back",
            length: 30,
            delegates: [
              {
                id: uuid.v4(),
                type: "Countdown",
                name: "Left",
                length: 10,
              },
              {
                id: uuid.v4(),
                type: "Countdown",
                name: "Middle",
                length: 10,
              },
              {
                id: uuid.v4(),
                type: "Countdown",
                name: "Right",
                length: 10,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: uuid.v4(),
    type: "Repeat",
    name: "Sun Salutations",
    length: 5,
    timesToRepeat: 10,
    delegate: {
      id: uuid.v4(),
      type: "Sequence",
      name: "Sun Salutations Set",
      length: 5,
      delegates: [
        {
          id: uuid.v4(),
          type: "Countdown",
          name: "Hands to prayer",
          length: 5,
        },
        {
          id: uuid.v4(),
          type: "Countdown",
          name: "Hands to the sky",
          length: 5,
        },
        {
          id: uuid.v4(),
          type: "Countdown",
          name: "Lean to the right",
          length: 5,
        },
        {
          id: uuid.v4(),
          type: "Countdown",
          name: "Hands to the sky",
          length: 5,
        },
        {
          id: uuid.v4(),
          type: "Countdown",
          name: "Lean to the left",
          length: 5,
        },
        {
          id: uuid.v4(),
          type: "Countdown",
          name: "Hands to the sky",
          length: 5,
        },
        {
          id: uuid.v4(),
          type: "Countdown",
          name: "Forward bend",
          length: 5,
        },
        {
          id: uuid.v4(),
          type: "Countdown",
          name: "Flat back",
          length: 5,
        },
        {
          id: uuid.v4(),
          type: "Countdown",
          name: "Chaturanga Dandasana",
          length: 5,
        },
        {
          id: uuid.v4(),
          type: "Countdown",
          name: "Downward facing dog",
          length: 5,
        },
        {
          id: uuid.v4(),
          type: "Countdown",
          name: "Step forward",
          length: 5,
        },
        {
          id: uuid.v4(),
          type: "Countdown",
          name: "Flat back",
          length: 5,
        },
        {
          id: uuid.v4(),
          type: "Countdown",
          name: "Forward bend",
          length: 5,
        },
        {
          id: uuid.v4(),
          type: "Countdown",
          name: "Hands to the sky",
          length: 5,
        },
      ],
    },
  },
];
