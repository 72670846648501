import { useNavigation } from "@react-navigation/native";
import { useMemo } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { Timer } from "../timer/Timers";
import TextPropertyView from "./TextPropertyView";

export default function EditSequence({ data, onUpdate }) {
  const navigation = useNavigation();
  let nameField = { type: "String", name: "name" };
  const delegates = useMemo(() => data.delegates || [], [data.delegates]);
  return (
    <View>
      <TextPropertyView
        key={nameField.name}
        data={data}
        field={nameField}
        onUpdate={onUpdate}
      />
      <TouchableOpacity
        style={{ padding: 24 }}
        onPress={() => {
          navigation.push("EditSequenceList", {
            title: `Edit ${data.name}`,
            data: delegates,
            setData: delegates => {
              onUpdate({
                ...data,
                delegates,
              });
            },
          });
        }}>
        <Text style={{ fontSize: 24, fontWeight: "bold" }}>{"Sequence"}</Text>
        <Text
          style={{
            flex: 1,
            fontSize: 18,
            marginTop: 12,
          }}>
          {Timer(data).getSubtitle()}
        </Text>
      </TouchableOpacity>
    </View>
  );
}
