import { View } from "react-native";
import { Timer } from "../timer/Timers";
import TextPropertyView from "./TextPropertyView";

export default function EditTabata({ data, onUpdate }) {
  let fields = [{ type: "String", name: "name" }, ...Timer(data).fields()];
  return (
    <View>
      {fields.map(field => (
        <TextPropertyView
          key={field.name}
          data={data}
          field={field}
          onUpdate={onUpdate}
        />
      ))}
    </View>
  );
}
