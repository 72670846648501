import React from "react";
import { TouchableOpacity, View, Text, StyleSheet } from "react-native";
import { TimerTypeMap } from "../timer/Timers";

export default function TimerTypeRow({ type, onPress }) {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress || (() => {})}>
      <View style={{ flex: 1 }}>
        <Text style={styles.title}>{type}</Text>
        <Text style={styles.subtitle}>{TimerTypeMap[type].description}</Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 24,
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
  },
  subtitle: {
    marginTop: 12,
    fontSize: 18,
  },
});
