import capitalize from "capitalize";
import { Text, TextInput, View } from "react-native";
import { Field } from "../timer/Timers";

export default function TextPropertyView({ data, field, onUpdate }) {
  let f = Field(field);
  let label = field.label || capitalize(field.name);
  let onValueChange = t => {
    onUpdate({ ...data, [field.name]: f.adapt(t) });
  };
  return (
    <View style={{ padding: 24 }}>
      <Text style={{ fontSize: 24, fontWeight: "bold" }}>{label}</Text>
      <TextInput
        keyboardType={field.type == "Int" ? "numeric" : "default"}
        style={{
          fontSize: 18,
          borderWidth: 1,
          padding: 8,
          borderRadius: 8,
          marginTop: 8,
        }}
        value={data[field.name] ? data[field.name].toString() : ""}
        onChangeText={onValueChange}
      />
    </View>
  );
}
