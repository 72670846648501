import { Audio, InterruptionModeAndroid, InterruptionModeIOS } from "expo-av";
import { useKeepAwake } from "expo-keep-awake";
import { speak } from "expo-speech";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { AppStyles } from "../../Styles";
import { formatMs } from "../timer/Timers";

export default function RunTimer({ route }) {
  useKeepAwake();
  let [isStarted, setIsStarted] = useState(true);
  let [startTime, setStartTime] = useState(Date.now());
  let [startValue, setStartValue] = useState(0);
  let [msPassed, setMsPassed] = useState(0);

  let initAudio =
    Platform.OS == "ios"
      ? useMemo(async () => {
          await Audio.setAudioModeAsync({
            interruptionModeIOS: InterruptionModeIOS.MixWithOthers,
            interruptionModeAndroid: InterruptionModeAndroid.DuckOthers,
            staysActiveInBackground: true,
            playsInSilentModeIOS: true,
          });
          let sound = new Audio.Sound();
          await sound.loadAsync(require("../../assets/silence.m4a"));
          await sound.setIsLoopingAsync(true);
          await sound.playAsync();
          return sound;
        }, [])
      : Promise.resolve();
  let playTick = async time => {
    await initAudio;
    speak(time + "");
  };
  let playAction = async action => {
    await initAudio;
    speak(action);
  };

  let { segments } = route.params;
  let i = segments.findIndex(
    s => s.start * 1000 <= msPassed && msPassed < s.end * 1000,
  );

  useEffect(() => {
    if (!isStarted || i == -1) return;
    const timer = setTimeout(() => {
      let prevTimeRemaining = segments[i]?.end * 1000 - msPassed;
      msPassed = startValue + (Date.now() - startTime);
      let timeRemaining = segments[i].end * 1000 - msPassed;
      let cues = [3, 2, 1];
      cues.forEach(cue => {
        if (prevTimeRemaining > cue * 1000 && timeRemaining <= cue * 1000) {
          playTick(cue);
        }
      });
      if (prevTimeRemaining > 0 && timeRemaining <= 0) {
        playAction((segments[i + 1] && segments[i + 1].action) || "Complete");
      }
      setMsPassed(msPassed);
    }, 100);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    return async () => {
      let sound = await initAudio;
      await sound?.stopAsync();
    };
  }, []);

  let startTimer = () => {
    setIsStarted(true);
    setStartValue(msPassed);
    setStartTime(Date.now());
  };
  let stopTimer = () => {
    setIsStarted(false);
  };

  let totalTimeRemaining = segments[segments.length - 1].end * 1000 - msPassed;
  return (
    <View
      style={{
        display: "flex",
        justifyContent: "space-between",
        height: "100%",
      }}>
      <ScrollView>
        <View style={StyleSheet.compose(AppStyles.card, { padding: 16 })}>
          <View style={styles.row}>
            <View style={styles.labelValueContainer}>
              <Text style={styles.value}>
                {segments[i]?.progress.trim() || " "}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <TouchableOpacity
              style={StyleSheet.compose(
                styles.labelValueContainer,
                Platform.select({
                  web: {
                    cursor: "pointer",
                  },
                }),
              )}
              onPress={() => {
                if (i == 0) return;
                msPassed =
                  segments[i == -1 ? segments.length - 1 : i - 1].start * 1000;
                setMsPassed(msPassed);
                startValue = msPassed;
                setStartValue(msPassed);
                startTime = Date.now();
                setStartTime(startTime);
              }}>
              <Text style={[styles.label, { fontSize: 16 }]}>Previous</Text>
              <Text style={[styles.value, { fontSize: 20 }]}>
                {i == -1
                  ? segments[segments.length - 1].action
                  : segments[i - 1]?.action || " "}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.row}>
            <TouchableOpacity
              style={StyleSheet.compose(
                styles.labelValueContainer,
                Platform.select({
                  web: {
                    cursor: "pointer",
                  },
                }),
              )}
              onPress={() => {
                if (i == -1 || i + 1 == segments.length) return;
                msPassed = segments[i + 1].start * 1000;
                setMsPassed(msPassed);
                startValue = msPassed;
                setStartValue(msPassed);
                startTime = Date.now();
                setStartTime(startTime);
              }}>
              <Text style={styles.label}>Next</Text>
              <Text style={styles.value}>
                {(i == -1 ? " " : segments[i + 1]?.action) || "Complete"}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
      <View style={StyleSheet.compose(AppStyles.card, { padding: 16 })}>
        <View style={styles.row}>
          <View style={styles.labelValueContainer}>
            <Text style={styles.action}>
              {i == -1 ? "Complete" : segments[i]?.action || " "}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View
            style={[
              styles.labelValueContainer,
              { flexDirection: "row", justifyContent: "space-between" },
            ]}>
            <Text style={styles.actionTimeRemaining}>
              {formatMs((segments[i]?.end || 0) * 1000 - msPassed)}
            </Text>
            <View>
              <View
                style={[
                  styles.labelValueContainer,
                  { alignItems: "flex-end" },
                ]}>
                <Text style={[styles.label, { fontSize: 16 }]}>Remaining</Text>
                <Text style={[styles.value, { fontSize: 20 }]}>
                  {formatMs(totalTimeRemaining)}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            margin: 16,
            justifyContent: "center",
          }}>
          <Button
            title='Start'
            disabled={isStarted || totalTimeRemaining <= 0}
            onPress={startTimer}
          />
          <Button
            style={{}}
            title='Stop'
            disabled={!isStarted || totalTimeRemaining <= 0}
            onPress={stopTimer}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  label: {
    fontSize: 24,
    fontWeight: "bold",
    margin: 8,
  },
  value: {
    fontSize: 32,
    margin: 8,
  },
  action: {
    fontSize: 48,
    margin: 8,
    fontWeight: "bold",
  },
  actionTimeRemaining: {
    fontSize: 64,
    margin: 8,
    fontWeight: "bold",
  },
  row: {
    flexDirection: "row",
  },
  labelValueContainer: {
    flex: 1,
  },
});
