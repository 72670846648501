import uuid from "react-native-uuid";

export const TimerTypeMap = {
  Repeat: {
    description: `A timer that runs another timer a set number of times.`,
    fields: [
      { type: "Int", name: "timesToRepeat", label: "Times to repeat" },
      { type: "Timer", name: "delegate" },
    ],
    getSubtitle: data => {
      return `${data.timesToRepeat || 0}x ${
        data.delegate ? data.delegate.name : ""
      }`;
    },
    getTotalTimeSeconds: data => {
      let t = data.delegate || NewTimer();
      return Timer(t).getTotalTimeSeconds() * (data.timesToRepeat || 0);
    },
    toSegments: data => {
      let time = 0;
      let ret = [];
      for (let i = 0; i < data.timesToRepeat; i++) {
        let prefix = data.name
          ? `${data.name} ${i + 1}/${data.timesToRepeat} `
          : "";
        Timer(data.delegate || NewTimer())
          .toSegments()
          .forEach(segment => {
            segment.progress = prefix + segment.progress;
            segment.start += time;
            segment.end += time;
            ret.push(segment);
          });
        time = ret[ret.length - 1].end;
      }
      return ret;
    },
  },
  Sequence: {
    description: `A timer that runs a set of timers sequentially.`,
    fields: [{ type: "TimerArray", name: "delegates" }],
    getSubtitle: data => {
      data.delegates = data.delegates || [];
      return data.delegates.length
        ? data.delegates.map(d => d.name).join(", ")
        : "(none)";
    },
    getTotalTimeSeconds: data => {
      data.delegates = data.delegates || [];
      return data.delegates.reduce((s, d) => {
        return s + Timer(d).getTotalTimeSeconds();
      }, 0);
    },
    toSegments: data => {
      data.delegates = data.delegates || [];
      let time = 0;
      let ret = [];
      data.delegates.forEach((delegate, i) => {
        let prefix = data.name
          ? `${data.name} ${i + 1}/${data.delegates.length}\n`
          : "";
        Timer(delegate)
          .toSegments()
          .forEach(segment => {
            segment.progress = prefix + segment.progress;
            segment.start += time;
            segment.end += time;
            ret.push(segment);
          });
        time = ret[ret.length - 1].end;
      });
      return ret;
    },
  },
  Countdown: {
    description: `A timer that simply counts down a set number of seconds.`,
    fields: [{ type: "Int", name: "length" }],
    getTotalTimeSeconds: data => {
      return data.length;
    },
    getSubtitle: data => {
      return `Countdown - ${data.length} Seconds`;
    },
    toSegments: data => {
      return [
        {
          start: 0,
          end: data.length,
          progress: "",
          action: data.name,
        },
      ];
    },
  },
  Tabata: {
    description: `A timer that alternates between an work period and a rest period for a number of rounds.`,
    fields: [
      { type: "Int", name: "rounds" },
      { type: "Int", name: "work" },
      { type: "Int", name: "rest" },
    ],
    getDelegate: data => {
      return {
        type: "Repeat",
        name: data.name,
        timesToRepeat: data.rounds,
        delegate: {
          type: "Sequence",
          delegates: [
            { type: "Countdown", name: data.name, length: data.work },
            { type: "Countdown", name: "Rest", length: data.rest },
          ],
        },
      };
    },
    getSubtitle: data => {
      return `Tabata - ${data.work || 0} Work / ${data.rest || 0} Rest / ${
        data.rounds || 0
      } Rounds`;
    },
    getTotalTimeSeconds: data => {
      return Timer(
        TimerTypeMap[data.type].getDelegate(data),
      ).getTotalTimeSeconds();
    },
    toSegments: data => {
      return Timer(TimerTypeMap[data.type].getDelegate(data)).toSegments();
    },
  },
};

export const NewTimer = () => {
  return {
    id: uuid.v4(),
    type: "Countdown",
    name: "My Timer",
    length: 30,
  };
};

export const Timer = data => ({
  fields: () => TimerTypeMap[data.type].fields,
  getFormattedTime: () => {
    let seconds = data.type
      ? TimerTypeMap[data.type].getTotalTimeSeconds(data)
      : 0;
    return formatMs(seconds * 1000);
  },
  getTotalTimeSeconds: () =>
    TimerTypeMap[data?.type]?.getTotalTimeSeconds(data) || 0,
  getSubtitle: () => TimerTypeMap[data.type].getSubtitle(data),
  toSegments: () => TimerTypeMap[data.type].toSegments(data),
});

const FieldTypeMap = {
  Int: {
    default: 0,
    adapt: data => {
      let n = parseInt(data);
      return isNaN(n) ? 0 : n;
    },
  },
  String: {
    default: "",
    adapt: data => {
      return data + "";
    },
  },
};

export const Field = data => {
  return {
    default: FieldTypeMap[data.type]?.default,
    adapt: v =>
      FieldTypeMap[data.type]?.adapt ? FieldTypeMap[data.type].adapt(v) : v,
  };
};

export const formatMs = ms => {
  if (ms <= 0) return "0:00";
  let c = Math.ceil(ms / 1000);
  let h = Math.floor(c / 3600);
  let m = Math.floor((c % 3600) / 60);
  let s = (c % 60).toString().padStart(2, "0");
  let str = `${m}:${s}`;
  if (h) str = `${h}:${m < 10 ? "0" : ""}${str}`;
  return str;
};
