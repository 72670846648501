import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { Timer } from "../timer/Timers";

export default function TimerRow({ data }) {
  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <Text style={styles.title}>{data.name}</Text>
        <Text numberOfLines={1} style={styles.subtitle}>
          {Timer(data).getSubtitle()}
        </Text>
      </View>
      <Text style={styles.time}>{Timer(data).getFormattedTime()}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    padding: 24,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
  },
  subtitle: {
    marginTop: 12,
    fontSize: 18,
  },
  time: {
    fontSize: 18,
    fontWeight: "bold",
  },
});
