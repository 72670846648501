import { Platform, ScrollView, StyleSheet, View } from "react-native";
import { AppStyles } from "../../Styles";
import TimerTypeRow from "../component/TimerTypeRow";
import { TimerTypeMap } from "../timer/Timers";

export default function SelectTimerType({ navigation, route }) {
  return (
    <ScrollView>
      {Object.keys(TimerTypeMap).map(t => (
        <View
          key={t}
          style={StyleSheet.compose(AppStyles.card, {
            ...Platform.select({
              web: {
                cursor: "pointer",
              },
            }),
          })}>
          <TimerTypeRow
            type={t}
            onPress={() => {
              route.params.onSelect(t);
              navigation.goBack();
            }}
          />
        </View>
      ))}
    </ScrollView>
  );
}
