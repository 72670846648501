import { useState } from "react";
import TimerList from "../component/TimerList";

export default function EditSequenceList({ route }) {
  let { data, setData } = route.params;
  let [delegates, setDelegates] = useState(data);
  return (
    <TimerList
      data={delegates}
      setData={delegates => {
        setDelegates(delegates);
        setData(delegates);
      }}
    />
  );
}
